import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { postData } from '../services/api';
import { saveToLocalStorage } from '../helpers/localStorageHelper';
import { ToastContainer, toast } from 'react-toastify';
import classes from './JoinOurCommunity.module.css';
import 'react-toastify/dist/ReactToastify.css';

const JoinOurCommunity = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const showWarning = (message) => {
        toast.warn(message);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSignup = async () => {
        const url = new URL(window.location.href); 
        const searchParams = new URLSearchParams(url.search);
        const code = searchParams.get('code');        
        const pc = searchParams.get('pc');        

        if(!code){
            showWarning('Invalid Referral Code');
            return;
        }

        if(!pc){
            showWarning('Package Code is required');
            return;
        }
        
        if(!name){
            showWarning('Name is required');
            return;
        }
        
        if(!email){
            showWarning('Email is required');
            return;
        }        

        setLoading(true); 

        try {
            let param_data = {};

            param_data = {
                fullname : name,
                email : email,
                sponsor_code : code,
                quantity : 1,
                package_code : pc,
            }
    
            const response = await postData('payment/signup', param_data);
            if (response?.data) {
                const checkoutUrl = response.data.attributes.checkout_url;
        
                param_data['paymentId'] = response.data.id; 
        
                saveToLocalStorage("paymentDetails", param_data);
          
                // Redirect to the checkout URL
                window.location.href = checkoutUrl;
            }

        } catch (error) {
            console.error("error on submit:", error);
            showWarning('Failed to process signup');
        } finally {
            setLoading(false); 
        }   
    };

    return (
        <div className={classes.joinOurCommunitySection}>
            <h2 className={classes.communityHeader}>✨ Join Our Community ✨</h2>
            <p className={classes.communityDescription}>
                Become a part of a growing family that values health and flavor. Get exclusive access to new products, special discounts, and more!
            </p>
            {/* <button className={classes.joinButton} onClick={handleOpenModal}>
                👇 Don't wait to make the healthy choice! <br />Be a Member Now
            </button> */}
            <button className={classes.joinButton} onClick={handleOpenModal}>
                <span className={classes.pointerArrow}>👉</span> Don't wait to make the healthy choice! <br />Be a Member Now
            </button>
            <button className={classes.floatingButton} onClick={handleOpenModal}>
                <span className={classes.pointerArrow}>👉</span> Join Now!
            </button>

            {isModalOpen && (
                <div className={classes.modal}>
                <div className={classes.modalContent}>
                    <span className={classes.closeButton} onClick={handleCloseModal}>&times;</span>
                    <h2 className={classes.modalHeader}>START YOUR JOURNEY</h2>
                    <form>
                        <label htmlFor="name">Name:</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Name"
                            required
                            className={classes.inputField}
                        />
                        <label htmlFor="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your Email"
                            required
                            className={classes.inputField}
                        />
                        <button type="button" className={classes.signupButton} onClick={handleSignup} disabled={loading}>
                            {loading ? <div className={classes.spinner}></div> : 'Claim Your Access'}
                        </button>                        
                    </form>
                </div>
                </div>
            )}

            <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </div>
    );
};

export default JoinOurCommunity;
