import React from 'react';
// import React, { useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import PublicHeader from '../components/layout/Header/PublicHeader';
import PublicFooter from '../components/layout/Footer/PublicFooter';
// import { postData } from '../services/api';
// import { saveToLocalStorage } from '../helpers/localStorageHelper';
import JoinOurCommunity from './JoinOurCommunity';
import classes from './Homepage.module.css';
import banner from '../assets/image/logo/mp3-banner.gif';

const Homepage = () => {
  
  return (
        <div className={classes.container}>

          <div className={classes['top-banner-section']}>
            <img src={banner} alt='MP3 Banner' className={classes.banner} />
          </div>
          <div className={classes['top-section']}>
            <h1>Welcome to King of Healthy Coffee!</h1>
            <h2>May kape' ka na, healthy pa</h2>

            <p className={classes['description']}>
            Indulge in the rich, aromatic bliss of coffee that's not just good—it's good for you! At King of Healthy Coffee, 
            we blend the finest beans with unique, wellness-boosting ingredients, ensuring each sip offers both exquisite taste 
            and health benefits.
            </p>
          </div>

          <div className={classes.whyChooseUsSection}>
            <h2 className={classes.whyChooseUsHeader}>Why Choose Us?</h2>
            <p className={classes.feature}><strong>Health Benefits:</strong> Each cup packs a powerful punch of antioxidants and nutrients.</p>
            <p className={classes.feature}><strong>Great Taste:</strong> Enjoy the robust flavors of premium coffee with every brew.</p>
            <p className={classes.feature}><strong>Convenience:</strong> Get your healthy fix delivered right to your doorstep.</p>
            <p className={classes['description']}>
              Transform your coffee ritual into a daily health boost. Whether you're a coffee aficionado or just looking to enhance your well-being, King of Healthy Coffee is here to elevate your everyday.
            </p>
          </div>

          <JoinOurCommunity /> 

          <div className={classes.royalRoadSection}>
            <p className={classes.royalRoadText}>
              Embrace the royal road to health with King of Healthy Coffee beverages — where every cup is a step towards a healthier you!
            </p>
          </div>

          <PublicFooter/>
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </div>
  );
};

export default Homepage;



